import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["image", "previous", "next", "title"]
  static values = { transitionInterval: Number }

  initialize() {
    const images = this.imageTargets

    if (images.length > 1) {
      let currentImage = 0;
      setInterval(() => {
        images[currentImage].classList.remove('opacity-100')
        images[currentImage].classList.add('opacity-0')
        currentImage = (currentImage + 1) % images.length
        images[currentImage].classList.remove('opacity-0')
        images[currentImage].classList.add('opacity-100')
      }, this.transitionIntervalValue)
    }

    this.titleTarget.classList.add('transition-opacity', 'duration-[4000ms]', 'ease-out', 'opacity-100')

  }
}