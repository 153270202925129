import Lightbox from "glightbox";
import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [ 'container' ]
  connect() {
    this.initLightbox();
  }

  initLightbox() {
    const lightbox = Lightbox({
       touchNavigation: true,
       loop: true,
       draggable: false
     });
  }
}
