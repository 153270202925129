import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [ "menuMobile", "button", "topbar", "middlebar", "bottombar" ]

  connect() {}

  toggleOverlay() {
    this.menuMobileTarget.classList.toggle("hidden")
    this.toggleMenu()
    setTimeout(() => {
      this.menuMobileTarget.classList.toggle("opacity-100")
    }, 50);
    document.body.classList.toggle("overflow-hidden")
  }

  checkSizeOverlay() {
    // used for the default mobile nav menu, not the offcanvas menu
    if(window.innerWidth > 768 && !this.menuMobileTarget.classList.contains("hidden")){
      this.toggleOverlay()
    }
  }

  toggleOffcanvas() {
    this.menuMobileTarget.classList.toggle('-translate-x-full')
    this.toggleMenu()
    document.body.classList.toggle("overflow-hidden")
  }

  checkSizeOffcanvas() {
    // used for the default mobile nav menu, not the offcanvas menu
    if(window.innerWidth > 768 && !this.menuMobileTarget.classList.contains("-translate-x-full")){
      this.toggleOffcanvas()
    }
  }

  toggleMenu(){
    this.buttonTarget.classList.toggle("text-navlink")
    this.buttonTarget.classList.toggle("text-navlink-mobile")

    this.topbarTarget.classList.toggle("rotate-45")
    this.topbarTarget.classList.toggle("-translate-y-2")

    this.middlebarTarget.classList.toggle("opacity-0")

    this.bottombarTarget.classList.toggle("-rotate-45")
    this.bottombarTarget.classList.toggle("translate-y-2")

  }
}
